import React from 'react';
// import icons 
import { FaGithub, FaInstagram, FaTwitter, FaLinkedinIn } from "react-icons/fa";
// import background 
import nav from "../assets/nav-bg.png"

const Nav = () => {
  return (
    <nav className='lg:fixed bottom-2  w-full overflow-hidden z-50 mb-6 lg:bottom-14 lg:mb-0'>
      <div className='container mx-auto'>
        <div className='w-fulls  h-[70px]  rounded-full max-w-[340px] mx-auto flex justify-between items-center text-2xl text-white/50'>
          <img src={nav} className=" h-[70px] w-[340px] rounded-full bg-blur"/>
          {/* instagram icon  */}
          <a href='https://www.instagram.com/nebiberke/' target='_blank' rel="noopener noreferrer" className='ml-5 icon cursor-pointer w-[50px] h-[50px] flex items-center justify-center' >
            <FaInstagram />
          </a>
          {/* twitter icon  */}
          <a href='https://twitter.com/nebiiberke' target='_blank' rel="noopener noreferrer" className='icon cursor-pointer w-[50px] h-[50px] flex items-center justify-center'>
            <FaTwitter />
          </a>
          {/* linkedin icon  */}
          <a href='https://www.linkedin.com/in/nebiberke/' target='_blank' rel="noopener noreferrer" className='icon cursor-pointer w-[50px] h-[50px] flex items-center justify-center'>
            <FaLinkedinIn />
          </a>
          {/* github icon  */}
          <a href='https://github.com/nebiberke' target='_blank' rel="noopener noreferrer" className='mr-5 icon cursor-pointer w-[50px] h-[50px] flex items-center justify-center'>
            <FaGithub />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Nav;