import React from 'react';
import Image from '../assets/pp.png';
import { motion } from "framer-motion";
import { TypeAnimation } from 'react-type-animation';
import { fadeIn } from "../variants";

const Banner = () => {
  return (
    <section className='min-h-[85vh] lg:min-h-[50vh] flex items-center'>
      <div className='container mx-auto lg-bottom'>
        <div className='flex flex-col gap-y-2 lg:flex-row lg-items-center lg:gap-x-24 flex-col-reverse' >
          {/* section start  */}
          <div className='flex-1 text-center font-secondary lg:text-left mt-10 lg:mt-28 lg:pt-10 mb-40'>
            <motion.h1 variants={fadeIn('up', 0.4)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }} className='text-[30px] font-bold leading-[0.8] lg:text-[70px] line font-size' style={{ wordWrap: "normal", wordWrap: "break-word", }}>
              NEBİ BERKE İÇÖZ
            </motion.h1>
            <br></br>
            <motion.div variants={fadeIn('up', 0.3)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }} className='mb-6 text-[36px] lg:text-[60px] font-secondary uppercase leading-[1] '>
              <span className='text-white mr-4 font-size'>I AM A</span>
              <TypeAnimation sequence={['Developer', 2000, 'Designer', 2000,]} speed={50} className='text-accent font-medium font-size' wrapper='span' repeat={Infinity} />
            </motion.div>
            <motion.div variants={fadeIn('up', 0.6)} initial='hidden' whileInView={'show'} viewport={{ once: false, amount: 0.7 }} className='items-center'>
              <a href='mailto:icoznebiberke@gmail.com' target='_blank'>
              <button onclick="window.location.href='https://www.google.com/'" className='btn btn-lg font-medium transform transition duration-500 hover:scale-110 flex justify-center items-center'>
                CONTACT ME
              </button>
              </a>
            </motion.div>
          </div>
          {/* section end  */}
          {/* photo start  */}
          <motion.div variants={fadeIn('down', 0.5)} whileInView={'show'} viewport={{ once: false, amount: 0.7 }} className='lg:flex flex-1 max-w-[320px] lg:max-w-[482px] mx-auto mb-10'>
            <img src={Image} alt="image" />
          </motion.div>
          {/* photo end  */}
        </div>
      </div>
    </section>
  );
};

export default Banner;
